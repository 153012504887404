import React,{ useState } from 'react'
import { FaArrowRight, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import "./index.css";
const HeaderHero = ({heading,toUrl,subTitle,viewAll=true}) => {
    const [isHovered,setIsHovered] = useState(false);
  return (
    <div
        className='header-hero-home'
    >
        <label
            className='heading'
        >
            <label style={{
                fontSize:20,
                fontWeight:"bold"
            }} >{heading}</label>
            <br/>
            {
                subTitle?
                <label
                style={{
                    fontSize:12,
                    color:"#919191"
                }}
                >{subTitle}</label>:
                null
            }
        </label>

        <Link
            className='view-more'
            to={`${toUrl}`}
            onMouseEnter={()=>setIsHovered(true)}
            onMouseLeave={()=>setIsHovered(false)}
        >
            
            {
                viewAll?
                <label>
                View All
            </label>:null
            }
            
        </Link>
    </div>
  )
}

export default HeaderHero