import React, { useState } from 'react'
import { Filters, Footer, Header, HeaderHero, ProductsBanner, SmallScreenHeader, TopBarCategories } from '../../components';
import { APPDATAURLS } from '../../utils/APPADATAURLS';
import "./index.css";
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ProductsLoading } from '../../components/Loading';
import { AiOutlineArrowDown } from 'react-icons/ai';

const sortBy = [
  {
    name:"Recently added"
  },
  {
    name:"Ascending order"
  },
  {
    name:"Descending order"
  },
  {
    name:"A to Z"
  },
  {
    name:"Z to A"
  },
];

const ProductsPage = () => {
    const [url,setUrl] = useState(`${APPDATAURLS.products}`);
    const [page,setPage] = useState(0);
    const [name,setName] = useState("All");
    const [modalOpen,setModalOpen] = useState(false);
    const [sortByOpen,setSortByOpen] = useState(false);

    const [products,setProducts] = useState({
      data:[],
      loading:false,
      error:false,
      message:"",
    });

    const fetchProducts = ()=>{
        setProducts({
        data:[],
        loading:true,
        error:false,
        message:""
        });
        fetch(`${url}&page=${page}`,{
        method:"get"
        }).then(req=>req.json()).then(response=>{
        setProducts({
        data:response.data,
        loading:false,
        error:response.error,
        message:response.message
        });
        }).catch(e=>{
        console.log(e);
        setProducts({
            data:[],
            loading:false,
            error:true,
            message:"An error occurred. Try again later"
        });
        })
    }

    useEffect(()=>{
      fetchProducts();
      window.scroll(0,0);
      console.log(url);
    },[page,url]);
  return (
    <div
    className='body'
    >
      <Modal show={modalOpen}
        onBackdropClick={()=>setModalOpen(false)}
        onHide={()=>setModalOpen(false)}
        size='xl'
      >
          <Modal.Header closeButton >
            <Modal.Title>Filters</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Filters setModalOpen={setModalOpen}/>
          </Modal.Body>
      </Modal>

      <Helmet>
        <html lang='en-us' />
        <title>Available Products</title>
        <meta
        name='available products'
        description="Currently available products at Savowarehouse. Reach out and grab yours."
        />
      </Helmet>
        <Header/>
        <div
        className='products-wrapper'
        >
            <SmallScreenHeader title={``} />
            
            
            <HeaderHero heading={`${name} products`}  viewAll={false}/>
            <TopBarCategories setUrl={setUrl} setName={setName}/>
            {/* <div className='products'>
            <div className='products-sort' > 
                <Button className='button-filter' onClick={()=>setModalOpen(!modalOpen)}>Filters</Button>
                <div className='products-sort-by' >
                  <div onClick={()=>setSortByOpen(!sortByOpen)} >
                  <label>Sort by:</label>
                  <AiOutlineArrowDown/>
                  </div>
                  <div className={`sort-list ${sortByOpen?"sort-by-open":null}`}>
                      {
                        sortBy.map((item,index)=>{
                          return <div 
                            className='sort-list-item' 
                            key={index} 
                            onClick={()=>{
                                setSortByOpen(false)
                          }}>
                                    <input type='radio' name='sort-item'/><label>{item.name}</label>
                                  </div>
                        })
                      }
                      
                  </div>
                </div>
              </div>
            </div> */}


            {
              products.loading?
              <ProductsLoading/>:
              products.data.length<1?
              <div>
                No products found for {name}
              </div>:
              <div className='products' >
                <div className='products-sort' > 
                <Button className='button-filter' onClick={()=>setModalOpen(!modalOpen)}>Filters</Button>
                <div/>
                <div className='products-sort-by' >
                  <div onClick={()=>setSortByOpen(!sortByOpen)} >
                  <label>Sort by:</label>
                  <AiOutlineArrowDown/>
                  </div>
                  <div className={`sort-list ${sortByOpen?"sort-by-open":null}`}>
                      {
                        sortBy.map((item,index)=>{
                          return <div className='sort-list-item' key={index}>
                                    <input type='radio' name='sort-item'/><label>{item.name}</label>
                                  </div>
                        })
                      }
                      
                  </div>
                </div>
              </div>
                <div  className='products-list'>
                  <div className='filters-holder'>
                  <Filters/>
                  </div>
                  
                  <ProductsBanner data={products} hasCategories />
                </div>
              </div>
              
            }
            <div
            className='buttons'
            >
              <div/>
              <div
              className='btns'
              >
                <Button
              style={{
                backgroundColor:"var(--app-color)",
                borderColor:"var(--app-color)"
              }}
              onClick={()=>setPage(page<1?0:page-1)}
              >Prev</Button>
              <Button
              style={{
                backgroundColor:"var(--app-color)",
                borderColor:"var(--app-color)"
              }}
              onClick={()=>setPage(page+1)}
              >Next</Button>
              </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default ProductsPage;