import React, { useEffect, useState } from 'react'
import { CustomButton, Delivery, Header, SmallScreenHeader } from '../../../components';
import "./index.css";

const Deliveries = () => {
    const [activeTab,setActiveTab] = useState(0);
    const [deliveries,setDeliveries] = useState(
        {
            loading:false,
            error:false,
            message:"",
            results:[
                    {   isDelivered:1,
                        customer:{
                            name:"User User",
                            phonenumber:"07123243536",
                            email:"user@gmail.com"
                        },
                        address:{
                            countryName:"Kenya",
                            countyName:"Nairobi",
                            cityName:"Nairobi",
                            locationName:"Embakasi",
                            description:"Near Petrol station",
                            latitude:null,
                            longitude:null
                        },
                        order:{
                            orderID:"HFAGAD",
                        },
                        products:[
                            {productName:"Product 1",quantity:1}
                        ],
                        companies:[
                            {companyName:"Company 1"}
                        ]
                    },
                    {   isDelivered:0,
                        customer:{
                            name:"User User",
                            phonenumber:"07123243536",
                            email:"user@gmail.com"
                        },
                        address:{
                            countryName:"Kenya",
                            countyName:"Nairobi",
                            cityName:"Nairobi",
                            locationName:"Embakasi",
                            description:"Near Petrol station",
                            latitude:-6.735612745,
                            longitude:36.4533632638723
                        },
                        order:{
                            orderID:"HFAGAD",
                        },
                        products:[
                            {productName:"Product 1",quantity:1}
                        ],
                        companies:[
                            {companyName:"Company 1"}
                        ]
                    },
                    
                ]
        }
    )
    const fetchDeliveries = ()=>{
        
    }
    useEffect(()=>{
        fetchDeliveries();
    },[])
  return (
    <div className='body' >
        <Header/>
        <div className='body-wrapper' >
        <SmallScreenHeader title={"My deliveries"} />
        <div className='btns' >
            <div className='delivery-btn'>
                <CustomButton
                pressAction={()=>setActiveTab(0)}
                title={"All deliveries"}
                width={"100%"}
                backgroundColor={activeTab==0?"var(--app-color)":"#f5f5f5"}
                color={activeTab==0?"#ffffff":"#000000"}
                />
            </div>
            
             <div className='delivery-btn'>
                <CustomButton
                pressAction={()=>setActiveTab(1)}
                title={"Pending"}
                width={"100%"}
                backgroundColor={activeTab==1?"var(--app-color)":"#f5f5f5"}
                color={activeTab==1?"#ffffff":"#000000"}
                />
            </div>

             <div className='delivery-btn'>
                <CustomButton
                pressAction={()=>setActiveTab(2)}
                title={"Delievered"}
                width={"100%"}
                backgroundColor={activeTab==2?"var(--app-color)":"#f5f5f5"}
                color={activeTab==2?"#ffffff":"#000000"}
                />
            </div>

        </div>
        <div className='deliveries' >
            {
                activeTab==0?deliveries.results.map((item,index)=>{
                    return <Delivery key={index} item={item} />
                }):
                activeTab==1?
                deliveries.results.filter(i=>i.isDelivered==0).map((item,index)=>{
                    return <Delivery key={index} item={item} />
                }):activeTab==2?
                deliveries.results.filter(i=>i.isDelivered==1).map((item,index)=>{
                    return <Delivery key={index} item={item} />
                }):<></>
            }
        </div>
        </div>
    
    </div>
  )
}

export default Deliveries;