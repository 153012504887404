import React from 'react'
import { Header, SmallScreenHeader } from '../../components'

const Help = () => {
  return (
    <div className='body' >
        <Header/>
        <div className='body-wrapper' >
            <SmallScreenHeader backButtonShown={true} title={"Help"} />
        </div>
    </div>
  )
}

export default Help