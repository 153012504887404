import React from 'react'
import "./index.css";
const ProductsLoading = ({isHorizontal=false}) => {
  return (
    <div
        className={`products-loading ${isHorizontal?"is-horizontal":""}`} 
    >
        {
            [1,2,3,4,5,6,7,8,9].map((item,index)=>{
                return <div className='product' key={index}>
                            <div className='product-img'></div>
                            <div className='label-one'></div>
                            <div className='label-two' ></div>
                        </div>
            })
        }
    </div>
  )
}

export default ProductsLoading;