import React from 'react'
import { BASEURL } from '../../utils/APPADATAURLS';
import "./index.css";
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Category = ({category}) => {
  const [imageLoaded,setImageLoaded] = useState(false);
  return (
    <Link
    to={`/shop/${category.categoryName}/${category.id}`}
    className='category-holder'
    >
        <img
        style={{
          display:imageLoaded?"flex":"none"
        }}
        onLoad={(e)=>{
                  setImageLoaded(true)}}
                onError={(e)=>{
                  setImageLoaded(false)}} src={`${BASEURL}/${category.categoryImage}`} />
        <img
        style={{
          display:imageLoaded?"none":"flex"
        }}
        src={`/assets/images/savo_logo.jpg`} className='default-category-image' />
        <label style={{fontSize:"var(--smallest-font-size)"}} >{category.categoryName}</label>
    </Link>
  )
}

export default Category