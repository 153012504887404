import React from 'react';
import "./index.css";

const HomeCategoriesLoading = () => {
  return (
    <div
    className='home-categories-loading'
    >
        {
            [1,2,3,4,5,6,7,8].map((item,index)=>{
                return <div key={index} className='category'>
                            <div className='category-img' />
                            <div className='category-label'/>
                        </div>
            })
        }
    </div>
  )
}

export default HomeCategoriesLoading;