import React, { useRef } from 'react';
import {AiFillCarryOut, AiOutlineAlipay, AiOutlineDollar, AiOutlineMoneyCollect, AiTwotonePhone} from "react-icons/ai";
import {FaFunnelDollar, FaMoneyCheck} from "react-icons/fa";
import "./index.css";

const HeaderBanner = () => {
  return (
    <div className='header_banner1' 
    >
      <div>
        <AiOutlineDollar size={40} fill='var(--app-color)' />
        <p>
          Secure payments
        </p>
      </div>

      <div>
        <FaMoneyCheck size={40} fill='var(--app-color)' />
        <p>
          Easier cashback
        </p>
      </div>

      <div>
        <AiTwotonePhone size={40} fill='var(--app-color)' />
        <p>
          24/7 online support
        </p>
      </div>
    </div>
  )
}

export default HeaderBanner;



export const RefExample = () => {
  const divRef = useRef();
  const update = ()=>{
    divRef.current.style.backgroundColor = "red";
  }
  return (
    <div ref={divRef} >
      <p>Ref example</p>
      <button onClick={update} >Change background color to red</button>
    </div>
  )
}
