import React from 'react'
import { Link } from 'react-router-dom';
import SideBar from '../SideBar';
import {FiX} from "react-icons/fi";
import "./index.css";
const SmallScreenSidebar = ({isVisible,setIsVisible}) => {
  return (
    <div
        className='small-screen-sidebar'
        style={{
            left:isVisible?0:-350,
            overflowY:"scroll",
            width:300
        }}
    >
        <div  
            className='logo'
            style={{
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between",
                padding:5,
                width:"100%"
            }}
            >
            <a href="/" style={{textDecoration:"none"}} className={"logo"}>chiroonagi.<label style={{
                    color:"var(--app-color)"
                }} >com</label></a>
            <FiX 
            onClick={()=>setIsVisible(false)}
            size={20} 
            fill='#000000' />
        </div>

        <div  style={{
                display:"flex",
                alignItems:"center",
                gap:10,
                padding:5,
                width:"100%"
            }}>
            <Link
                to={"/login"}
                style={{textDecoration:"none"}}
            >
                Login
            </Link>

            <Link
                to={"/register"}
                style={{textDecoration:"none"}}
            >
                Register
            </Link>
        </div>

        <label
        style={{
            margin:5,
            textDecoration:"underline"
        }}
        >Categories</label>

        <SideBar setIsVisible={setIsVisible} />

        <p>Other</p>
        
    </div>
  )
}

export default SmallScreenSidebar;