import {Link} from "react-router-dom";
import { FaStar } from "react-icons/fa";
import "./index.css";
const Advert = ()=>{
    return(
        <div className={"advert"} >
            <Link to="/"
            style={{
                textDecoration:"none",
                color:"#ffffff"
            }}
            >savowarehouse.<label style={{
                color:"var(--app-color)"
            }} >com</label></Link>
            <Link
            className="company-name"
            >
                Savo 
                <FaStar className="star" fill={"var(--app-color)"} /> 
                Warehouse
                <FaStar className="star" fill={"var(--app-color)"} />
            </Link>
            <Link to="/" className="shop-now">
                SHOP NOW
            </Link>
        </div>
    )
}

export default Advert;