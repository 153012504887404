import React from 'react'
import { Header, SmallScreenHeader } from '../../../components';
import Lottie from 'lottie-react';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
  const navigate = useNavigate();
  return (
    <div className='body' >
      <Header/>
      <div className='addresses-wrapper'>
        <SmallScreenHeader title={"Settings"}/>

        <Lottie style={{width:50,height:50}} loop animationData={require("../../../assets/lottie_animations/circle_progress.json")} />
        <div 
        style={{
          position:"absolute",
          bottom:30,
          right:10,
          width:40,
          height:40,
          backgroundColor:"var(--app-color)",
          borderRadius:20,
          display:"flex",
          alignItems:"center",
          justifyContent:"center"
        }}
        >
          <FaPlus size={20} fill='#ffffff' onClick={()=>navigate("/profile/add-address")} />
        </div>
      </div>
    </div>
  )
}

export default Settings;