import React from 'react'
import "./index.css";
import { FaFacebookMessenger } from 'react-icons/fa';
const CustomButton = ({title,
                        pressAction,
                        mouseEnter,
                        mouseLeave,
                        borderRadius,
                        backgroundColor,
                        width,
                        color,
                        accessoryLeft,
                        accessoryRight,
                        disabled=false,
                        display
                      }) => {
  return (
    <button
    style={{color,width,borderRadius,backgroundColor,display}}
    onClick={pressAction}
    onMouseEnter={mouseEnter}
    onMouseLeave={mouseLeave}
    className="custom_button"
    disabled={disabled}
    >
      {accessoryLeft}
      {title}
      {accessoryRight}
    </button>
  )
}

export default CustomButton