import React from 'react'
import "./index.css";
import { BottomTabs, Footer, Header, HeaderHero, ProductsBanner, SmallScreenHeader } from '../../components';
const FlashSale = () => {
  return (
    <div className='body' >
        <Header/>
        <div className='body-wrapper' >
            <SmallScreenHeader backButtonShown={true} title={"Flash sales"} />
            <HeaderHero heading={"Flash sales"} viewAll={false}/>
            
            <ProductsBanner />
            
        </div>
        <BottomTabs/>
        <Footer/>  
    </div>
  )
}

export default FlashSale;