import React, { useEffect, useState } from 'react'
import { BottomTabs, Footer, Header, ProductsBanner, SmallScreenHeader } from '../../components';
import { useContext } from 'react';
import { FavoritesContext, UserContext } from '../../contexts';
import { Auth } from '..';
import { APPDATAURLS } from '../../utils/APPADATAURLS';
import Lottie from 'lottie-react';
import { ProductsLoading } from '../../components/Loading';




const FavoritesPage = () => {
    const [userData,setUserData] = useContext(UserContext);
    const [favoritesData,setFavoritesData] = useContext(FavoritesContext);
    const [isBigScreen,setIsBigScreen] = useState(true);
    const [data,setData] = useState({
      loading:false,
      error:false,
      message:"",
      data:[]
    })

    
    let url = `${APPDATAURLS.userFavorites}${userData.data.id}`;
  
    const fetchProducts = ()=>{
        setData({
        data:[],
        loading:true,
        error:false,
        message:""
        });
        let userID = userData.data;
        // let data = JSON.parse(localStorage.getItem("user"));
        
        // userID = data.data;
        fetch(`${APPDATAURLS.userFavorites}${userID.id}`,{
        method:"get"
        }).then(req=>req.json()).
        then(response=>{
        setData({
        data:response.data,
        loading:false,
        error:response.error,
        message:response.message
        });
        }).catch(e=>{
        console.log(e);
        setData({
            data:[],
            loading:false,
            error:true,
            message:"An error occurred. Try again later"
        });
        })
    }

    const fetchFavorites = ()=>{
    fetch(`${APPDATAURLS.userFavorites}${userData.data.id}`,{
        method:"GET"
    }).then(req=>req.json()).then(response=>{
        setFavoritesData(response.data)
    }).catch(err=>{
        console.log(err);
    })
  }

    useEffect(()=>{
      fetchFavorites();
      fetchProducts();
    },[userData.data])

    useEffect(()=>{
      window.scroll(0,0);
      window.addEventListener('resize', ()=>{
            if(window.innerWidth < 800){
                return setIsBigScreen(false);
            }else{
                 return setIsBigScreen(true); 
            }
        });

        if(window.innerWidth < 800){
            return setIsBigScreen(false);
        }else{
           return setIsBigScreen(true); 
        }
    },[window.innerWidth])
  return (
    <>{
        userData.loggedIn?
        <div className='body' >
        <Header/>

        
        <div className='body-wrapper' >
            <SmallScreenHeader backButtonShown={false} title={"My Favorites"} />
            <label className='title'>My Favorites</label>
            {
              data.error?<div  style={{
                marginTop:50,
                marginBottom:10,
                backgroundColor:"#f5f5f5",
                color:"black",
                display:"flex",
                alignItems:"center",
                width:"100%",
                justifyContent:"center",
                padding:10,
              }} > <label>Oops an error occurred. Try again later.</label> </div>:
              data.loading?
              <div
                className='data-holder loading-container'
              >
                {/*<Lottie 
                style={{
                  width:50,
                height:50
              }} 
              loop 
              animationData={require("../../assets/lottie_animations/circle_progress.json")}/>*/}
              <ProductsLoading/>
            </div>:
              data.data.length>0?<ProductsBanner 
            url={url} 
            marginTop={isBigScreen?80:40}
            data={data}
            />:<div
                style={{
                    width:"100%",
                    height:400,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                }}
                >
                    <div
                    style={{
                        width:"fit-content"
                    }}
                    >
                        <label
                        style={{
                            fontSize:20,
                            fontWeight:"bold",
                            color:"#919191"
                        }}
                        >Their is nothing here</label>
                        <Lottie loop animationData={require("../../assets/lottie_animations/empty.json")}/>
                    </div>

                </div>
            }
            
        </div>
        <BottomTabs/>
        <Footer/>    
    </div>:
    <Auth.LoginPage/>
    }</>
    
  )
}

export default FavoritesPage;