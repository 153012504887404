import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet';
import { FaMapPin } from 'react-icons/fa';
import iconMarker from 'leaflet/dist/images/marker-icon.png'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Header, SmallScreenHeader } from '../../components';
import "./addresses.css";
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLEMAPSAPIKEY } from '../../utils/APPADATAURLS';
const icon = L.icon({ 
  iconUrl: iconMarker, 
});



const MapPage = () => {
   const params = useParams();
   const latitude = parseFloat(params.latitude);
   const longitude = parseFloat(params.longitude);
   const [mapKey,setMapKey] = useState(process.env.GOOGLEMAPSAPIKEY);
   console.log(mapKey);

   console.log(latitude);
  const {isLoaded} = useJsApiLoader({
    id:"google-map-script",
    googleMapsApiKey:GOOGLEMAPSAPIKEY
  });  

  const [map,setMap] = useState(null);
  const [directionsService,setDirectionsService] = useState(null);
  const [directionsDisplay,setDirectionsDisplay] = useState(null);
  const [isUpdated,setIsUpdated] = useState(false);
  let [isDisplayed,setIsDisplayed] = useState(false);
  useEffect(()=>{
    setDirectionsDisplay(null);
    if(map){
      setDirectionsService(new window.google.maps.DirectionsService());
      setDirectionsDisplay(new window.google.maps.DirectionsRenderer());
      
    }

    if(directionsDisplay){
      setIsDisplayed(true);
      directionsDisplay.setMap(map);  
      directionsDisplay.setOptions({suppressMarkers:true});
      //showDirection(delivery.results.products[0].latitude,delivery.results.products[0].longitude);
      //setIsUpdated(!isUpdated);
    }

  },[map,isUpdated]);

  return (
    <div className='body' >
        <Header/>
        <div className='addresses-wrapper' >
            <SmallScreenHeader title={"Location"} />
        {
            isLoaded && latitude?
            <>
            {/* <GoogleMap
            mapContainerStyle={{
              width:"100%",
              height:"100%"
            }}
            zoom={12}

            // center={{lat:latitude,lng:longitude}}
            center={{lat:latitude,lng:longitude}}
            onLoad={(map)=>{
              const bounds = new window.google.maps.LatLngBounds({lat:latitude,lng:longitude});
              //map.fitBounds(bounds);
              setMap(map);
              setIsUpdated(!isUpdated);
            }}
            onUnmount={(map)=>{
                setMap(null);
            }}
            >
              <MarkerF 
                onClick={(e)=>console.log(e)} 
                position={{lat:latitude,lng:longitude}} 
                title='Me'
                label={"Me"}
                draggable
                />
            
            </GoogleMap> */}

          <MapContainer 
            center={[latitude,longitude]} 
            zoom={13} 
            scrollWheelZoom={false} 
            style={{width:"100%",height:"100%",zIndex:1}}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
            <Marker position={[latitude,longitude]} icon={icon} key={"User"}>
              <Tooltip>My selected location</Tooltip>
              <Popup>My location</Popup>
            </Marker>
            
          </MapContainer>
            
            </>
        :
        <MapContainer 
            center={[latitude,longitude]} 
            zoom={13} 
            scrollWheelZoom={false} 
            style={{width:"100%",height:"100%",zIndex:1}}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
            <Marker position={[latitude,longitude]} icon={icon} key={"User"}>
              <Tooltip>My selected location</Tooltip>
              <Popup>My location</Popup>
            </Marker>
            
          </MapContainer>
        }
        </div>
      
        
    </div>
  )
}

export default MapPage;