import React, { useEffect, useRef, useState } from "react";
import { useFetchData } from "../../hooks";
import { APPDATAURLS } from "../../utils/APPADATAURLS";
import "./index.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const TopBarCategories = ({setUrl,setName}) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [individualLength,setIndividualLength] = useState(0);
  const { loading, error, message, results } = useFetchData(
    APPDATAURLS.categories
  );
    const divRef = useRef();
    const containerRef = useRef();

  const previous = ()=>{
    divRef.current.scrollLeft -= 200;
  }
  const next = ()=>{
    divRef.current.scrollLeft += 200;
  }
  useEffect(()=>{
    setIndividualLength(containerRef.current.getBoundingClientRect().width/results.length);
  },results);
  return (
    <div className="top-bar-categories" ref={containerRef}  >
      <div className="left">
      <FaChevronLeft onClick={previous} />
      </div>

      {
        console.log(individualLength)
      }
      
      <div className="right" >
      <FaChevronRight onClick={next}/>
      </div>
      {error ? (
        <label>{message}</label>
      ) : loading ? (
        <label>Loading...</label>
      ) : results ? (
        <div ref={divRef} className="categories">
          <div
            onClick={()=>{
              setName("All");
              setUrl(APPDATAURLS.products);
              setSelectedIndex(null);
            }}
            className={`top-bar-category ${
              selectedIndex == null ? "isActive" : ""
            }`}
          >
            All
          </div>
          {results.map((category, index) => {
            return (
              <div
                key={index}
                onClick={()=>{
                  setName(category.categoryName);
                  setUrl(`${APPDATAURLS.categoryProducts}${category.id}&userID=`);
                  setSelectedIndex(index);
                  //divRef.current.scrollLeft = index*100;
                }}
                className={`top-bar-category ${
                  selectedIndex == index ? "isActive" : ""
                }`}
              >
                {category.categoryName}{" "}
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TopBarCategories;
