import React from 'react'
import { Button } from 'react-bootstrap';

function SellerRegistraion({setShowModal}) {
  return (
    <div
        style={{
            display:"flex",
            flexDirection:"column",
            gap:10
        }}
    >
        <label>Name</label>
        <input type='text' />
        <label>Location</label>
        <input type='text'/>
        <Button
            variant='success'
            onClick={()=>setShowModal(false)}
        >Save</Button>
    </div>
  )
}

export default SellerRegistraion;