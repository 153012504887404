import React,{useRef,useState,useEffect} from 'react'
import { Fragment } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import "./index.css";
import Product from './Product';
import { APPDATAURLS } from '../../utils/APPADATAURLS';
import { ProductLoading } from '../Loading';


const HorizontalProductsBanner = ({url=`${APPDATAURLS.products}&userID=`}) => {
    const banner_holder_ref = useRef(0);
    const [isHidden,setIsHidden] = useState(true);
    const [visibleArrow,setVisibleArrow] = useState({
        right:false,
        left:true,
        scrolledWidth:0
    });


    const [products,setProducts] = useState({
        data:[],
        loading:true,
        error:false,
        message:""
    });

    const fetchProducts = ()=>{
        setProducts({
        data:[],
        loading:true,
        error:false,
        message:""
        });
        fetch(url,{
        method:"get"
        }).then(req=>req.json()).then(response=>{
        setProducts({
        data:response.data,
        loading:false,
        error:response.error,
        message:response.message
        });
        }).catch(e=>{
        console.log(e);
        setProducts({
            data:[],
            loading:false,
            error:true,
            message:"An error occurred. Try again later"
        });
        })
    }

    useEffect(()=>{
        fetchProducts();

    },[]);

  return (
    <div
        
        className='horizontal-banner-holder'

        onMouseEnter={()=>setIsHidden(false)}
        onMouseLeave={()=>setIsHidden(true)}
    >
        <div
            className={`move_button prev ${!isHidden && (visibleArrow.scrolledWidth<(260*products.length))?"move_button_visible":"move_button_hidden"}`}
        >
            <div className='icon' >
                <FaChevronLeft  
                    size={30} 
                    fill={"#ffffff"} 
                    onClick={()=>{
                        banner_holder_ref.current.scrollLeft += 270;
                        setVisibleArrow({...visibleArrow,scrolledWidth:visibleArrow.scrolledWidth+270});
                    }}
                />
            </div>
            
        </div>

        <div 
            ref={banner_holder_ref} 
            className={"banner-container"}
            >
            {
            products.error?
            <div>{products.message}</div>:
            products.loading?
            <>
            <ProductLoading/>
            </>:
            products.data.map((item,index)=>{
                
                return<Product
                    key={index}
                    product={item}
                />
            })}
        </div>
        
        <div className={`move_button next ${!isHidden && visibleArrow.scrolledWidth>=270?"move_button_visible":"move_button_hidden"}`} >
            <div className='icon' >
                <FaChevronRight 
                    size={30} 
                    fill={"#ffffff"}
                    onClick={()=>{
                        banner_holder_ref.current.scrollLeft -= 270;
                        setVisibleArrow({...visibleArrow,scrolledWidth:visibleArrow.scrolledWidth-270});
                    }}
                    />
                </div>
        </div>
    </div>
  )
}



export default HorizontalProductsBanner;