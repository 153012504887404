import React,{useContext, useState,useEffect} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {FaSearch,FaUser,FaInbox,FaBook,FaShoppingCart, FaHeart,FaShoppingBag, FaHome} from "react-icons/fa";
import "./index.css";
import SmallScreenSidebar from "../SmallScreenSideBar";
import { CartContext } from "../../contexts/cartContext";
import { FiLogOut,FiMenu } from "react-icons/fi";
import { UserContext } from "../../contexts/UserContext";
import { Button, Modal } from "react-bootstrap";
import SellerRegistraion from "../SellerRegistration";
import { HomeCategoriesLoading } from "../Loading";
import { APIURL } from "../../utils/APPADATAURLS";
import Search from "../Search";
import { AiOutlineHeart, AiOutlineHome, AiOutlineLogout, AiOutlineShoppingCart, AiOutlineUser } from "react-icons/ai";
const Header = ()=>{
    const navigate = useNavigate();
    const [isVisible,setIsVisible] = useState(false);
    const [showModal,setShowModal] = useState(false);
    const [searchText,setSearchText] = useState("");
    const [data,setData] = useState({
        loading:false,
        results:[],
        error:false,
        message:""
    });
    const [loggedIn,setLoggedIn] = useState(true);

    const [cartData,setCartData] = useContext(CartContext);
    const total = cartData.map(item=>Number(item.quantity)).reduce((prev,curr)=>prev+curr,0);

    const [userData,setUserData] = useContext(UserContext);
    
    const logout = ()=>{
        setUserData({loggedIn:false,data:{}});
        localStorage.removeItem("user");
    }

    const goToSellerDashboard = ()=>{
        navigate("/shop/dashboard/1");
    }

    const [activeTab,setActiveTab] = useState(null);
    const location = useLocation();
    useEffect(()=>{
        setActiveTab(location.pathname)
    },[location]);

    const search = (text)=>{
        if(text.length<2){
          setData({
              loading:false,
              results:[],
              error:false,
              message:""
          })
            return false;
        }
        setData({
            loading:true,
            results:[],
            error:false,
            message:""
        })
        let formData = new FormData();
        formData.append("search","search");
        formData.append("searchQuery",text);
        fetch(APIURL,{
            method:"POST",
            body:formData,
        }).then(req=>req.json()).then(res=>{
            setData({
                loading:false,
                results:res.data,
                error:res.error,
                message:res.message
            })
        }).catch(e=>{
            setData({
                loading:false,
                results:[],
                error:true,
                message:"An error occurred. Please try again."
            })
        })
    }
    useEffect(()=>{
        window.addEventListener('resize', ()=>{
            if(window.innerWidth > 800){
                setIsVisible(false);
            }
        });
    })

    return(
        <div className={"header"}>
            <SmallScreenSidebar isVisible={isVisible} setIsVisible={setIsVisible} />

            {/* <Modal
                show={showModal}
                size="xl"
                onBackdropClick={()=>setShowModal(false)}
                onHide={()=>setShowModal(false)}
            >
                <Modal.Header
                    closeButton
                >
                    <Modal.Title>
                        Seller registration
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SellerRegistraion setShowModal={setShowModal} />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={()=>setShowModal(false)}
                        variant="primary"

                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> */}

            <div className="top" >
                    <div className="logo-and-hamburger">
                        <Link to="/" className={"logo"}>
                            <img src="/assets/images/savo_logo.jpg" />
                        </Link>

                        <div
                            style={{
                                display:"flex",
                                flexDirection:"row",
                                gap:10,
                                alignItems:"center",
                                justifyContent:"center",
                            }}
                        >
                            <Link 
                            className="hamburger"
                            to="/cart"
                            style={{
                                width:"fit-content",
                                height:"fit-content",
                                position:"relative"
                            }}
                            >
                                <label
                                style={{
                                    display:"flex",
                                    position:"absolute",
                                    top:-16,
                                    right:0,
                                    color:"orange"
                                }}
                                >{total}</label>
                                <FaShoppingCart fill={"var(--app-color)"}  />
                            </Link>

                            <FiMenu 
                            className="hamburger" 
                            fill={"var(--app-color)"} 
                            color={"var(--app-color)"} 
                            onClick={()=>setIsVisible(isVisible?false:true)}
                            />
                        </div>
                    </div>

                    <div className={"search"}>
                        <input 
                        onChange={(e)=>{
                            search(e.target.value);
                            setSearchText(e.target.value);
                        }}
                        type={"search"}
                        placeholder={"Search by product name, category, company..."} 
                        className={"search-input"}/>
                        <button className="search-btn">Search</button>
                        <FaSearch 
                        className="search-icon"
                        size={20} 
                        fill={"var(--app-color)"} />
                        <div
                        className="search-results"
                        style={{
                            display:searchText.length>2?"flex":"none"
                        }}
                        >
                            {
                                data.error?
                                <div
                                
                                >{data.message}</div>:
                                data.loading?
                                <HomeCategoriesLoading/>:
                                data.results.length>0?
                                data.results.map((product,index)=>{
                                                    return <Search 
                                                                    key={index} 
                                                                    product={product} 
                                                                    setSearchText={setSearchText} />}):
                                <div>
                                    <label>No results found matching {searchText}</label>
                                </div> 
                            }
                            
                        </div>
                    </div>
                    
                    <div 
                    
                    onClick={userData.loggedIn?()=>{}:()=>setShowModal(false)} className={"sell"}>
                        Sell on savowarehouse
                    </div>
                    <div
                    style={{
                        display:"flex",
                        flexDirection:"row",
                        height:"100%",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:15
                    }}
                    >
                        <Link to="/" className="signin-signup"
                        >
                            <AiOutlineHome color={activeTab=="/"?"var(--app-color)":"var(--app-color)"} size={26}/>
                            {
                                activeTab=="/"?
                            
                            <label>Home</label>:<></>
                            }
                        </Link>

                        <Link to="/cart" className="signin-signup"
                        >
                            <label
                            style={{
                                display:"flex",
                                position:"absolute",
                                top:-14,
                                right:0,
                                color:activeTab=="/cart"?"#000000":"#000000",
                                fontWeight:"700",
                                fontSize:"var(--default-font-size)"

                            }}
                            >{total}</label>
                            <AiOutlineShoppingCart 
                                color={activeTab=="/cart"?"var(--app-color)":"var(--app-color)"}
                                size={25} />
                            {
                                activeTab=="/cart"?
                            
                            <label>Cart</label>:<></>
                            }
                        </Link>
                        {
                            userData.loggedIn?
                            <>
                            

                            {/*<Link to="/profile/inbox" className="signin-signup">
                                <label
                                style={{
                                    display:"flex",
                                    position:"absolute",
                                    top:-18,
                                    right:0,
                                    color:"orange"

                                }}
                                >0</label>
                                <FaInbox fill={"var(--app-color)"} />
                            </Link>*/}

                            <Link to="/favorites" className="signin-signup">
                                <AiOutlineHeart color={activeTab=="/favorites"?"var(--app-color)":"var(--app-color)"}
                                size={25}
                                />
                                {
                                activeTab=="/favorites"?
                            
                                <label>Favorites</label>:<></>
                                }
                            </Link>
                            <Link to="/profile" className="signin-signup">
                                <AiOutlineUser 
                                color={activeTab=="/profile"?"var(--app-color)":"var(--app-color)"} 
                                size={25}
                                />
                                {
                                activeTab=="/profile"?
                            
                                <label>Profile</label>:<></>
                                }
                            </Link>
                            
                            </>:
                            <Link to="/login" className="signin-signup">
                                Sign In | Sign Up
                            </Link>
                        }
                        

                        

                        {
                            userData.loggedIn?
                            <Link 
                            onClick={logout}
                            to="#" 
                            className="signin-signup">
                                <AiOutlineLogout color={"red"} size={25} />
                            </Link>
                            :<></>
                        }
                        
                    </div>
            </div>
            
            <div className="bottom" >
                <div/>
                <div className="items" >
                    <Link className="link" to={"/"} >Home</Link>
                    <Link className="link" to={"/flash-sales"} >Flash sales</Link>
                    <Link className="link" to={"/help"} >Help</Link>
                    <Link className="link" to={"/return-policy"} >Return policy</Link>
                    <Link className="link" to={"/contact-us"} >Contact us</Link>
                    <Link className="link" to={"/about-us"} >About us</Link>
                </div>
                <div/>
            </div>
            
        </div>
    )
}

export default Header;