import React from 'react'
import { BASEURL } from '../../utils/APPADATAURLS';
import { Link, useNavigate } from 'react-router-dom';
import "./index.css";

const Search = ({product,setSearchText}) => {
    const navigate = useNavigate();
  return (
    <div
    onClick={(e)=>{
        navigate(`/products/${product.productName}/${product.productID}`);
        setSearchText("");}}
    className='search-result'
    >
        <img
        src={`${BASEURL}/${product.productImage}`}
        />
        <div className='name-and-price' >
            <label>{product.productName.length>55?`${product.productName.substring(0,55)}...`:product.productName}</label>
            <label>Ksh.{product.productPrice}</label>
        </div>
    </div>
  )
}

export default Search;