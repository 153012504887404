import React from 'react';
import "./index.css";
import CustomButton from '../CustomButton';
import { useNavigate } from 'react-router-dom';
import { AiOutlineFileDone } from 'react-icons/ai';
import { FaBookmark } from 'react-icons/fa';

const Delivery = ({item}) => {
    const navigate = useNavigate();
  return (
    <div 
    className='delivery' 
    style={{
            
        }} >

        <div
        style={{
            position:"absolute",
            right:5,
            top:5,
            display:'flex',
            flexDirection:"row",
            alignItems:"center",
            gap:10,
            color:item.isDelivered?"green":"gray",
            fontSize:14,
            fontWeight:700,
        }}
        >
            {item.isDelivered?"Delivered":"Pending"}<FaBookmark/>
        </div>
        <div className='customer' >
            {item.isDelivered?"Delivered":"Deliver"} to : {item.customer.name}
        </div>

        <div className='customer-adderess' >
            {item.isDelivered?"Delivered":"Deliver"}  at:
            {
            item.address.latitude?
            <div className='item.address' >
                <label className='one'>
                Pinned location
                </label>
                <CustomButton
                title={"View On Map"}
                backgroundColor={"var(--app-color)"}
                pressAction={()=>navigate(`/location/${item.address.latitude}/${item.address.longitude}`)}
                />
            </div>:
            <div className='address' >
                <label
                className='one'
                >{item.address.countyName.toUpperCase()}</label>
                <label
                className='two'
                >{item.address.cityName},</label>
                <label
                className='three'
                >{item.address.locationName}, </label> 
                <label
                className='two'
                >{item.address.description},</label>
            </div>
            }
        </div>
            {
                !item.isDelivered?
                <div style={{
            width:"100%",
            marginTop:10,
        }} >
            <CustomButton
                title={"Mark as delivered"}
                backgroundColor={"var(--app-color)"}
                pressAction={()=>{}}
                />
        </div>:<></>
            }
        

    </div>
  )
}

export default Delivery;