import React,{useState} from 'react'
import { Button, Card, InputGroup, Modal, Table } from 'react-bootstrap';
import {FaBackward,FaForward} from "react-icons/fa";

function Products() {
  const [show,setShow] = useState(false);
  const [showEditModal,setShowEditModal] = useState(false);
  return (
    <div
    style={{
        marginTop:76,
        padding:10
    }}
    >

      <Modal
        show={show}
        onHide={()=>setShow(false)}
        animation={true}
        size='xl'
      >
        <Modal.Header closeButton >
          <Modal.Title>
            Product name
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Product details</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
          onClick={()=>setShow(false)}
          >OK</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showEditModal}
        onHide={()=>setShowEditModal(false)}
        animation={true}
        size='xl'
      >
        <Modal.Header closeButton >
          <Modal.Title>
            Edit Product name
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Product name</p>
          <input type='text' placeholder='product name...'/>
          <p>Product price</p>
          <input type='text' placeholder='product price...'/>
          
          <p>Product discount</p>
          <input type='text' placeholder='product discount...'/>
        </Modal.Body>
        <Modal.Footer>
          <Button
          onClick={()=>setShowEditModal(false)}
          >Update</Button>
        </Modal.Footer>
      </Modal>

    <Card>
      <Card.Header>
        <Card.Title>
          Products
        </Card.Title>
      </Card.Header>
      <Card.Body>

        <Table responsive striped >
          <thead>
            <th>#</th>
            <th>image</th>
            <th>name</th>
            <th>price</th>
            <th>actions</th>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td><img src='' alt="product image" /></td>
              <td>product 1</td>
              <td>1000</td>
              <td 
              style={{
                display:"flex",
                gap:5
              }}
              >
                <Button variant='primary' onClick={()=>setShow(true)} >View</Button>
                <Button variant='secondary' onClick={()=>setShowEditModal(true)} >Edit</Button>
                <Button variant='danger' >Delete</Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        <Button
          variant='success'
          onClick={()=>setShowEditModal(false)}
          style={{
            marginRight:5,
            gap:5
          }}
          >
            <FaBackward/>
          </Button>
        <Button
          onClick={()=>setShowEditModal(false)}
          style={{
            marginRight:5,
            gap:5
          }}
          >
            <FaForward/>
            </Button>
      </Card.Footer>
    </Card>
      
    </div>
  )
}



export default Products;