import React, { useContext } from 'react';
import "./index.css";
import { BASEURL } from '../../utils/APPADATAURLS';
import { FiStar } from 'react-icons/fi';
import { useState } from 'react';
import { UserContext } from '../../contexts';

const Rating = ({item}) => {
    const [imageLoaded,setImageLoaded] = useState(false);
    const [userData,setUserData] = useContext(UserContext);
  return (
    <div className='product-rating' >
        <img
        style={{
                display:imageLoaded?"flex":"none",
              }}
        src={`${BASEURL}/${item.image}`}
        onLoad={(e)=>{
                  setImageLoaded(true)}}
                onError={(e)=>{
                  setImageLoaded(false)}}
                onLoadStart={(e)=>{
                }}
                onLoadedData={(e)=>{
                }}
            />

            <img
              style={{
                display:imageLoaded?"none":"flex",
              }}
            className='default'
            src='/assets/images/savo_logo.jpg'
            />
        <div className='product-rating-holder' >
            <label>
              {
                userData?.data?.id==item?.customerID?"You":
                `${item.firstName} ${item.lastName}`
              }
            </label>
            <div
            style={{
                marginBottom:10
            }}
            >
                {
                    [1,2,3,4,5].map((_,index)=>{
                        return <FiStar 
                            color={(index+1)<=item.stars?'var(--app-color)':'#b9b4b4'}  
                            fill={(index+1)<=item.stars?'var(--app-color)':'#b9b4b4'} 
                            size={15} 
                            />
                    })
                }
            </div>
            <p>
                {item.review}
            </p>
        </div>
    </div>
  )
}

export default Rating;