import React, { useContext, useEffect, useState } from 'react'
import { Address, Header, SmallScreenHeader } from '../../../components';
import Lottie from 'lottie-react';
import "./addresses.css";
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { APPDATAURLS } from '../../../utils/APPADATAURLS';
import { UserContext } from '../../../contexts';
import { Auth } from '../..';

const Addresses = () => {
  const navigate = useNavigate();
  const [userData,setUserData] = useContext(UserContext);
  const [data,setData] = useState({
    loading:false,
    error:false,
    message:"",
    results:[],
  });

  const getAddresses = ()=>{
    setData({
      loading:true,
      error:false,
      message:"",
      results:[],
    });
    fetch(`${APPDATAURLS.customerAddresses}${userData.data.id}`,{
          method:"GET"
      }).
      then(req=>req.json()).
      then(response=>{
        console.log(response);
      setData({
        loading:false,
        error:response.error,
        message:response.message,
        results:response.data,
      });
      
      }).catch(e=>{
          setData({
          loading:false,
          error:true,
          message:e.message,
          results:[],
        });
      })
  }

  useEffect(()=>{
    getAddresses();
  },[userData.loggedIn])
  return (
    <>
    {
      userData.loggedIn?
      <div className='body' >
      <Header/>
      <div className='addresses-wrapper'>
        <SmallScreenHeader title={"My Addresses"}/>
        {
          data.error?
          <div>
            An error occurred!
          </div>:
          data.loading?
          <Lottie 
          style={{width:50,height:50}} 
          loop 
          animationData={require("../../../assets/lottie_animations/circle_progress.json")} />:
          data.results.length>0?
          <div
            className='addresses-holder'
            style={{
              display:"flex",
              flexDirection:"column",
              gap:10,
            }}
          >
            {
              data.results.map((address,index)=>{
                return <Address address={address} />;
              })
            }
          </div>:
          <div
                style={{
                    width:"100%",
                    height:400,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                }}
                >
                    <div
                    style={{
                        width:"fit-content"
                    }}
                    >
                        <label
                        style={{
                            fontSize:20,
                            fontWeight:"bold",
                            color:"#919191"
                        }}
                        >Their is nothing here</label>
                        <Lottie loop animationData={require("../../../assets/lottie_animations/empty.json")}/>
                    </div>

                </div>
        }
        
        <div 
        style={{
          position:"fixed",
          width:40,
          height:40,
          bottom:30,
          right:20,
          top:"calc(100vh-100px)",
          backgroundColor:"var(--app-color)",
          borderRadius:20,
          display:"flex",
          alignItems:"center",
          justifyContent:"center"
        }}
        >
          <FaPlus size={20} fill='#ffffff' onClick={()=>navigate("/profile/add-address")} />
        </div>
      </div>
    </div>:
    <Auth.LoginPage/>
    }
    </>
  )
}

export default Addresses;