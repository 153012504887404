import React from 'react'
import { FaArrowAltCircleUp, FaHeadphones } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function RightFloaters() {
  return (
    <div
        style={{
                display:"flex",
                flexDirection:"column",
                gap:10,
            }}
            >
                    <Link
                        to={"/"}
                    >
                        <button
                        style={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            backgroundColor:"#225ea3",
                            color:"#ffffff",
                            border:"none",
                            borderRadius:20,
                            padding:5,
                            width:40,
                            height:40,
                        }}
                    >  
                    <FaHeadphones 
                    size={20} 
                    fill={"#ffffff"}
                    /></button>
                    </Link>

                    <button
                    onClick={()=>{
                        window.scrollTo(0,0)
                    }}
                        style={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            backgroundColor:"var(--app-color)",
                            color:"#ffffff",
                            border:"none",
                            borderRadius:20,
                            padding:5,
                            width:40,
                            height:40,
                        }}
                    >  
                    <FaArrowAltCircleUp 
                    size={20} 
                    fill={"#ffffff"}
                    /></button>
                </div>
  )
}

export default RightFloaters;