import React,{useState,useEffect} from 'react'
import { CustomButton, Header, SmallScreenHeader } from '../../../components';
import { useNavigate,useParams } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../contexts';
import { APIURL, APPDATAURLS } from '../../../utils/APPADATAURLS';
import Select from 'react-select';
import "./addresses.css";

const EditAddress = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [userData,setUserData] = useContext(UserContext);
  const [address,setAddress] = useState({
    data:{},
    loading:false,
    error:false,
    message:""
  });

  const [credentials,setCredentials] = useState({
    address:null,
    city:null,
    pinCode:null,
    state:null
  });




    const [locationIndex,setLocationIndex] = useState({
    countryIndex:0,
    countyIndex:0,
    cityIndex:0,
    locationIndex:0
  });

  const [availableLocations,setAvailableLocations] = useState({
    countries:[],
    counties:[],
    cities:[],
    locations:[]
  });

  const [userSelection,setUserSelection] = useState({
    countryID:null,
    countyID:null,
    cityID:null,
    locationID:null,
    description:null
  });

  const [data,setData] = useState({
    loading:false,
    error:false,
    message:"",
    results:[],
  });
  const fectchLocations = ()=>{
    setData({
      loading:true,
      error:false,
      message:"",
      results:[],
    });
    fetch(APPDATAURLS.getAvailableLocations,
      {method:"GET"}).
      then(req=>req.json()).
      then(res=>{
        console.log(res.data[0].counties);
        setData({
          loading:false,
          error:res.error,
          message:res.message,
          results:res.data,
        });
        setAvailableLocations({
          ...availableLocations,countries:res.data,
        });
      }).catch(e=>{
        setData({
          loading:false,
          error:true,
          message:"An error occurred while getting locations: Try again later.",
          results:[],
        });
      })
  }

  const fetchAddress = ()=>{
    setAddress({
      data:{},
      loading:true,
      error:false,
      message:""
      });
    fetch(`${APPDATAURLS.userAddress}${params.addressID}&userID=${userData.data.userID}`,{
      method:"get",
    }).then(req=>req.json()).
    then(response=>{
      setAddress({
      data:response.data,
      loading:false,
      error:response.error,
      message:response.message
      });
    }).catch(err=>{
      console.log(err);
      setAddress({
      data:{},
      loading:false,
      error:true,
      message:err.message
      });
    })
  }

  const fetchUserAddress = ()=>{
    setAddress({
      data:{},
      loading:true,
      error:false,
      message:""
      });
    fetch(`${APPDATAURLS.customerAddress}${params.addressID}&userID=${userData.data.id}`,{
      method:"get",
    }).then(req=>req.json()).
    then(response=>{
      setAddress({
      data:response.data,
      loading:false,
      error:response.error,
      message:response.message
      });
    }).catch(err=>{
      console.log(err);
      setAddress({
      data:{},
      loading:false,
      error:true,
      message:err.message
      });
    })
  }
  
  const update = ()=>{
    let formData = new FormData();
    formData.append("edit-user-address","add");
    formData.append("countryID",userSelection.countryID??address.data.countryID);
    formData.append("countyID",userSelection.countyID??address.data.countyID);
    formData.append("cityID",userSelection.cityID??address.data.cityID);
    formData.append("locationID",userSelection.locationID??address.data.locationID);
    formData.append("description",userSelection.description??address.data.description);
    formData.append("addressID",params.addressID);
    fetch(APIURL,{
      method:"POST",
      body:formData
    }).then(req=>req.json()).
    then(res=>{
      console.log(res);
      setCredentials({
        address:res.data.shippingAddress,
        city:res.data.shippingCity,
        pinCode:res.data.shippingPincode,
        state:res.data.shippingState
      });
      //navigate("/profile/addresses");
      window.history.back();
    }).catch(e=>{
      console.log(e);
    })
    
  }

  useEffect(()=>{
    fetchAddress();
    fetchUserAddress();
    fectchLocations();
    window.scroll(0,0);
  },[]);
  return (
    <div className='body' >
      <Header/>
      <div
      className='addresses-wrapper'
      >
        <SmallScreenHeader title={"Edit address"} />
        {
          address.error?
          <div>Error</div>:
          address.loading?
          <div>Loading...</div>:
          address.data?
          <>
            <p
            className='heading'
            >Edit address</p>
            
        <Select
        options={availableLocations.countries.map((item,index)=>{
          return {value:item.countryName,label:item.countryName};
        })}
            placeholder={address.data.countryName?address.data.countryName:"Select country"}
            styles={{
              control:(baseStyles,state)=>({
                ...baseStyles,
                width:"100%",
                paddingLeft:15,
                marginTop:5,
                marginBottom:5
              })
            }}
          onChange={(e)=>{
            let index = availableLocations.countries.map((item)=>item.countryName).indexOf(e.value);
            setLocationIndex({...locationIndex,countryIndex:index==-1?0:index});
            setAvailableLocations({...availableLocations,
              counties:[],
              locations:[],
              cities:[]});
            setAvailableLocations({...availableLocations,
              counties:data.results[index==-1?0:index].counties,
              locations:[],
              cities:[]});
              setUserSelection({...userSelection,countryID:data.results[index==-1?0:index].countryID});
              console.log(userSelection,data.results[index==-1?0:index].countryID);
          }}
        />


      <Select
        options={availableLocations.counties.map((item,index)=>{
          return {value:item.countyName,label:item.countyName};
        })}
            placeholder={address.data.countyName?address.data.countyName:"Select county"}
            styles={{
              control:(baseStyles,state)=>({
                ...baseStyles,
                width:"100%",
                paddingLeft:15,
                marginTop:5,
                marginBottom:5
              })
            }}
          onChange={(e)=>{
            let index = availableLocations.counties.map((item)=>item.countyName).indexOf(e.value);
            setLocationIndex({...locationIndex,countyIndex:index==-1?0:index});
            setAvailableLocations({...availableLocations,
              cities:data.results[locationIndex.countryIndex].
              counties[index==-1?0:index].
              cities,
              locations:[]});
            setUserSelection({...userSelection,countyID:data.results[locationIndex.countryIndex].counties[index==-1?0:index].countyID});
            console.log(userSelection,data.results[locationIndex.countryIndex].counties[index==-1?0:index].countyID);
          }}
        />

        <Select
        options={availableLocations.cities.map((item,index)=>{
          return {value:item.cityName,label:item.cityName};
        })}
            placeholder={address.data.cityName?address.data.cityName:"Select city"}
            styles={{
              control:(baseStyles,state)=>({
                ...baseStyles,
                width:"100%",
                paddingLeft:15,
                marginTop:5,
                marginBottom:5
              })
            }}
          onChange={(e)=>{
            let index = availableLocations.cities.map((item)=>item.cityName).indexOf(e.value);
            setLocationIndex({...locationIndex,cityIndex:index==-1?0:index});
            setAvailableLocations({...availableLocations,
              locations:data.results[locationIndex.countryIndex].
              counties[locationIndex.countyIndex].
              cities[index==-1?0:index].
              locations});
            setUserSelection({...userSelection,cityID:data.results[locationIndex.countryIndex].counties[locationIndex.countyIndex].cities[index==-1?0:index].cityID});
              console.log(data.results[locationIndex.countryIndex].counties[locationIndex.countyIndex].cities[index==-1?0:index].cityID);
          }}
        />

        <Select
        
        options={availableLocations.locations.map((item,index)=>{
          return {value:item.locationName,label:item.locationName};
        })}
            placeholder={address.data.locationName?address.data.locationName:"Select location"}
            styles={{
              control:(baseStyles,state)=>({
                ...baseStyles,
                width:"100%",
                paddingLeft:15,
                marginTop:5,
                marginBottom:5
              })
            }}
          onChange={(e)=>{
            let index = availableLocations.locations.map((item)=>item.locationName).indexOf(e.value);
            setLocationIndex({...locationIndex,locationIndex:index==-1?0:index});
            setUserSelection({...userSelection,
                          locationID:data.results[locationIndex.countryIndex].
                          counties[locationIndex.countyIndex].
                          cities[locationIndex.cityIndex].
                          locations[index==-1?0:index].locationID});
          }}
        />
        <textarea 
        onChange={(e)=>setUserSelection({...userSelection,description:e.target.value})}
        className="address-input" 
        style={{height:100}} 
        placeholder={address.data.description?address.data.description:'(Optional) Write a brief description of your region...'} >
          {address.data.description}
        </textarea>
            
            
            <CustomButton
            pressAction={update}
            title={"Save"}
            backgroundColor={"var(--app-color)"}
            borderRadius={20}
            />





          </>:
          <div>The address you are looking for was not found</div>
        }
        
      </div>
    </div>
  )
}

export default EditAddress;