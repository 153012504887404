import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//import * as serviceWorker from "./serviceWorker.js";

// if("serviceWorker" in navigator){
//     window.addEventListener("load",(e)=>{
//         navigator.serviceWorker.register("./serviceWorker.js")
//         .then(registration=>{
//             console.log(registration);
//         })
//         .catch(e=>{
//             console.log(e);
//         })
//     })
// }
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

//serviceWorker.register();

reportWebVitals();
