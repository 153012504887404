import React,{useContext, useEffect,useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import PieChart from './charts/PieChart';
import LineChart from './charts/LineChart';
import Products from './Products';
import {FaFirstOrder, FaHamburger,FaSearch} from "react-icons/fa";
import Orders from './Orders';
import { UserContext } from '../../contexts/UserContext';

function Dashboard() {
  const navigate = useNavigate();
  const [segments, setSegments] = useState([]);
  const [isVisible,setIsVisible] = useState(false);

  const [active,setActive] = useState(0);
  const [userData,setUserData] = useContext(UserContext);
  const getUser = async ()=>{

  }
  useEffect(()=>{
    const userInfo = localStorage.getItem("user");
    if(userInfo !== null){
      setUserData(JSON.parse(userInfo));
    }

    if(!userData.loggedIn){
      navigate("/");
    }

    },[]);
  return (
    <>
    <div className={"header"}>
            <div className="logo-and-hamburger">
                <p className={"logo"}>kenyaecom.<label style={{
                    color:"#e4762c"
                }} >com</label></p>

                <FaHamburger 
                className="hamburger" 
                fill={"#e4762c"} 
                onClick={()=>setIsVisible(isVisible?false:true)}
                />
            </div>
            

            <div className={"search"}>
                <input 
                type={"search"}
                placeholder={"Search..."} 
                className={"search-input"}/>
                <FaSearch 
                className="search-icon"
                size={20} 
                fill={"#e4762c"} />
            </div>
            
            <Link to="/" className={"sell"}>
                Home
            </Link>

            <div
            style={{
                display:"flex",
                flexDirection:"row",
                height:"100%",
                alignItems:"center",
                justifyContent:"space-between",
                gap:10
            }}
            >

              <a 
                className={"sell"}
                onClick={()=>setActive(0)}
                href="#"
                style={{
                    backgroundColor:active==0?"green":"white",
                    color:active==0?"white":"black"
                }}
                >
                Dashboard
            </a>

            <a 
            className={"sell"}
            onClick={()=>setActive(1)}
                href="#"
                style={{
                    backgroundColor:active==1?"green":"white",
                    color:active==1?"white":"black"
                }}
                >Products</a>
            
            <a 
            className={"sell"}
            onClick={()=>setActive(2)}
                href="#"
                style={{
                    backgroundColor:active==2?"green":"white",
                    color:active==2?"white":"black"
                }}
                >Orders</a>

            </div>

            
        </div>

    {
        active==0?
        <ShopDashboard/>:
        active==1?
        <Products/>:
        <Orders/>
    }

    </>
  )
}

const ShopDashboard = ()=>{
    return (
    <div style={{
        marginTop:76
    }} >
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3   p-3 bg-light ">
            <div className="d-flex justify-content-around py-4 px-2 bg-white align-items-center border border-secondary shadow-sm">
              <i className="bi bi-currency-dollar text-success fs-1"></i>
              <div>
                <span>Sales</span>
                <h2>234</h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3   p-3 bg-light ">
            <div className="d-flex justify-content-around py-4 px-2 bg-white align-items-center  border border-secondary shadow-sm ">
              <i className="bi bi-currency-dollar text-warning fs-1"></i>
              <div>
                <span>Revenue</span>
                <h2>234</h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3   p-3 bg-light ">
            <div className="d-flex justify-content-around py-4 px-2 bg-white align-items-center border border-secondary shadow-sm">
              <i className="bi bi-truck text-primary fs-1"></i>
              <div>
                <span>Delivery</span>
                <h2>234</h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3  p-3 bg-light ">
            <div className="d-flex justify-content-around py-4 px-2 bg-white align-items-center border border-secondary shadow-sm ">
              <i className="bi bi-graph-up-arrow text-danger  fs-1"></i>
              <div>
                <span>Increase</span>
                <h2>20%</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 p-3">
            <LineChart/>
          </div>
          <div className="col-12 col-md-4 p-3">
            <PieChart/>
          </div>
        </div>
  </div>
  </div>
  )
}

export default Dashboard;