import React from 'react'
import { Header } from '../../components';
import { FiSend } from 'react-icons/fi';
import car from "../../assets/images/camaro.jpg";
import "./inbox.css";

function Inbox() {
  return (
    <div
    style={{
      width:"100%"
    }}
    >
      <Header/>
      <div
      className="chats"
      style={{
        marginTop:76
      }}
      >
        <div
        className="side-menu-chats"
        >
          <div
            className="side-menu-chats-header"
            >
              <label>Chats</label>
          </div>

          <div
            className="side-menu-chats-users"
            >
              <label>No chats yet</label>
          </div>
        </div>

        <div
          className="chat-area"
          >
            <div
            className="chat-area-header"
            >
              <img
              src={car}/>
              <label>User name</label>
            </div>

            <div
            className="chat-area-messages"
            >

            </div>

            <div
            className="chat-area-compose-message"
            >
              <textarea
              placeholder='Compose a message...'
              />
              <FiSend
                className="send-icon"
              />
            </div>

        </div>
      </div>
    </div>
  )
}

export default Inbox;