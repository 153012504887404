import React, { useEffect, useState } from 'react';
import "./index.css";
import { BottomTabs, Header, SmallScreenHeader } from '../../components';
import { APIURL } from '../../utils/APPADATAURLS';
import Search from '../../components/Search';
import { HomeCategoriesLoading } from '../../components/Loading';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const SearchScreen = () => {
    const [searchText,setSearchText] = useState("");
    const [data,setData] = useState({
        loading:false,
        results:[],
        error:false,
        message:""
    });
    const search = (text)=>{
        if(text.length<2){
          setData({
              loading:false,
              results:[],
              error:false,
              message:""
          })
            return false;
        }
        setData({
            loading:true,
            results:[],
            error:false,
            message:""
        })
        let formData = new FormData();
        formData.append("search","search");
        formData.append("searchQuery",text);
        fetch(APIURL,{
            method:"POST",
            body:formData,
        }).then(req=>req.json()).then(res=>{
            setData({
                loading:false,
                results:res.data,
                error:res.error,
                message:res.message
            })
        }).catch(e=>{
            setData({
                loading:false,
                results:[],
                error:true,
                message:"An error occurred. Please try again."
            })
        })
    }
    const navigate = useNavigate();
    useEffect(()=>{
      window.addEventListener("resize",(e)=>{
        if(window.innerWidth >768){
          navigate("/");
        }
      });
      
    },window.innerWidth)
  return (
    <div className='body' >
      <Header/>
      <div className='body-wrapper' >
        <div className='small-screen-search' >
          <input
          autoFocus={true}
          onChange={(e)=>{
                    search(e.target.value);
                    setSearchText(e.target.value);
                }}
                type={"search"}
                placeholder={"Search by product name, category, company..."} 
                className={"search-input"}
        />
        <FaSearch 
                className="search-icon"
                size={20} 
                fill={"var(--app-color)"} />
        </div>
      </div>
      <div className='results' >
        {
            data.error?
              <div>{data.message}</div>:
                  data.loading?
                  <HomeCategoriesLoading/>:
                  data.results.length>0?
                  data.results.map((product,index)=>{
                          return <Search 
                                  key={index} 
                                  product={product} 
                                  setSearchText={setSearchText} />}):
                  <div>
                      <label>{searchText.length>2?`No results found matching ${searchText}`:""}</label>
                  </div> 
          }
      </div>
      <BottomTabs/>
    </div>
  )
}

export default SearchScreen;