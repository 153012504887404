import React from 'react';
import "./index.css";
import Lottie from 'lottie-react';

const ProgressItem = ({title,isCompleted}) => {
  return (
    <div
    className={'progress-item'}
    >
        <div 
        className='bar'
        >
            <div
            style={{
              backgroundColor:isCompleted?"green":""
            }}
            className={`horizontal-bar ${isCompleted?"active":""}`}  />
            <div
            style={{
              backgroundColor:isCompleted?"green":""
            }}
            className={`round ${isCompleted?"active":""}`} >
              {
                isCompleted && <Lottie animationData={require("../../assets/lottie_animations/tick.json")} loop={false} />
              }
              
            </div>
        </div>
        
        <label>{title}</label>

    </div>
  )
}

export default ProgressItem;