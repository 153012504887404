import React, { useContext } from 'react';
import {Link,useLocation,useNavigate} from "react-router-dom";
import { BottomTabs, CustomButton } from '../../../components';
import "./index.css";
import { FiSend } from 'react-icons/fi';
import { UserContext } from '../../../contexts/UserContext';
import { useState } from 'react';
import { APIURL, APPDATAURLS } from '../../../utils/APPADATAURLS';
import { useCookies } from 'react-cookie';
import { Button, Modal } from 'react-bootstrap';
import Lottie from 'lottie-react';
const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData,setUserData] = useContext(UserContext);
  const [credentials,setCredentials] = useState({
    email:"",
    password:"",
  });

  const [cookies,setCookies] = useCookies(["user"]);

  const [showModal,setShowModal] = useState(0);
  const [email,setEmail] = useState("");

  const [data,setData] = useState({
    results:[],
    error:false,
    loading:false,
    message:"",
  });

  const [errors,setErrors] = useState({
    state:false,
    loading:false,
    message:"",
  });

  const forgotPassword = ()=>{
    setErrors({
      state:false,
      loading:true,
      message:""
    });
  let formData = new FormData();
      formData.append("forgot-password","forgot");
      formData.append("email",email);
      fetch(APIURL,{
        method:"POST",
        body:formData
      }).
      then(req=>req.json()).
      then(res=>{
        console.log(res);
        setErrors({
          state:res.error,
          loading:false,
          message:res.message
        });
        if(!res.error){
          setTimeout(()=>{
            setShowModal(false);
          },3000);
        }
        
      }).catch(e=>{
        console.log(e);
        setErrors({
          state:true,
          loading:false,
          message:"Ooops, an error occurred. Try again later."
        });
      })
}


  const handleLogin = ()=>{
    if(credentials.email == ""){
      setData({
        error:true,
        loading:false,
        message:"Email is required!",
        data:[]
      });
      return;
    }

    if(credentials.password == ""){
      setData({
        error:true,
        loading:false,
        message:"Password is required!",
        data:[]
      });
      return;
    }

    setData({
        error:false,
        loading:true,
        message:"",
        data:[]
      });
    

    let formData = new FormData();
    formData.append("login","login");
    formData.append("email",credentials.email);
    formData.append("password",credentials.password);
    fetch(APIURL,{
      method:"POST",
      body:formData
    }).then(req=>req.json()).then(res=>{
      setData({
        error:res.error,
        loading:false,
        message:res.message,
        data:[]
      });
      if(!res.error){
        setUserData({loggedIn:true,data:res.data});
        localStorage.setItem("user",JSON.stringify({loggedIn:true,data:res.data}));
        setCookies("user",JSON.stringify({loggedIn:true,data:res.data}));
        if(location.pathname == "/login"){
          navigate("/");
        }
      }
    }).catch(e=>{
      setData({
        error:true,
        loading:false,
        message:e.message,
        data:[]
      });
    })
    
    
  }
  return (
    <div
    className="body"
    >
      <Modal
      show={showModal}
      onBackdropClick={()=>setShowModal(false)}
      size='xl'
      onHide={()=>setShowModal(false)}
      >
        <Modal.Header closeButton >
          <Modal.Title>
            Forgot password?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
        style={{
          display:"flex",
          flexDirection:"column"
        }}
        >
          {
            errors.message.length>0?
            <label style={{
              color:errors.state?"red":"green"
            }} >{errors.message}</label>:
            errors.loading?<label>Loading...</label>:
            <></>
          }
          <label>Email</label>
          <input value={email} onChange={(e)=>setEmail(e.target.value)}/>
        </Modal.Body>
        <Modal.Footer>
          <Button
          variant='success'
          onClick={()=>{
            forgotPassword();  
          }}
          >
            Request password reset
          </Button>
        </Modal.Footer>
      </Modal>
      
      <div
      className="login_body"
      >
        <img src={"/assets/images/savo_logo.jpg"}/>

        <div className="container" >
          {/* <div style={{
              height:300,
              width:300,
              borderBottomLeftRadius:300,
              borderTopLeftRadius:0,
              backgroundColor:"red",
              position:"absolute",
              zIndex:1
            }} ></div> */}
          <div
          className="login_credentials"
          style={{
            position:"relative",
            zIndex:2
          }}
          >
            <div style={{
              width:"100%",
              display:"flex",
              alignItems:"center",
              justifyContent:"center",
            }} >
              <Link
                to={"/"}
                className="website_name"
                >
                  <img src='/assets/images/savo_logo.jpg' style={{
                    width:80,
                    height:80,
                    objectFit:"cover",
                    margin:2,
                    borderRadius:5,
                  }} />
                </Link>
            </div>
            

            

            <p className="hello" >Hello!</p>
            <p className="login_into_account" >Log In to your Account!</p>
            <div
              className="credentials_container"
            >
              {
                data.error?
                <label
                style={{
                  color:"red"
                }}
                >{data.message}</label>:
                data.loading?
                <Lottie 
                    size={100}
                    loop 
                    animationData={require("../../../assets/lottie_animations/loading3.json")}/>:
                null
              }
              <label>Email</label>
              <input 
              type="email" 
              onChange={(e)=>setCredentials({...credentials,email:e.target.value})} 
              placeholder="Email..."/>
              <label>Password</label>
              <input 
                type="password" 
                onChange={(e)=>setCredentials({...credentials,password:e.target.value})} 
                placeholder="Password..."/>
              <div className="login_register" >
                <div className='login-btn'>
                  <CustomButton 
                  pressAction={handleLogin}
                  title={"Sign In"} 
                  borderRadius={20}
                  backgroundColor={"var(--app-color)"}
                  accessoryRight={<FiSend style={{marginLeft:10}} />}
                  />
                </div>
                
                <p>
                  Dont have an account?
                  <Link className="link" to="/register" >Register here</Link>
                </p>
              </div>
               <p style={{
                  color:"var(--app-color)",
                  cursor:"pointer",
                  fontWeight:'bolder'
                }} onClick={()=>setShowModal(true)}>
                  Forgot password?
                </p>

              <div
              style={{
                width:"100%",
                display:"flex",
                flexDirection:"row",
                marginTop:30
              }}
              >
                <input
                type="checkbox"
                style={{
                  width:20,
                  height:20,
                }}
                />
                <p>Remember me</p>
              </div>
              
              
            </div>

            
          </div>
          
        </div>

      </div>
        <BottomTabs/>
    </div>
  )
}

export default LoginPage