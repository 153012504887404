import React from 'react'
import { Header, SmallScreenHeader } from '../../components';
import Lottie from 'lottie-react';
//import "./addresses.css";
import { FaPlus } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { APPDATAURLS } from '../../utils/APPADATAURLS';
import { useState } from 'react';
import { useEffect } from 'react';
import { Rating } from '../../components';

const ProductRatings = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [data,setData] = useState({
    loading:false,
    error:false,
    message:"",
    results:[],
  });
  const getRatings = ()=>{
    setData({
      loading:true,
      error:false,
      message:"",
      results:[],
    });
    fetch(`${APPDATAURLS.productRatings}${params.productID}`,{
          method:"GET"
      }).
      then(req=>req.json()).
      then(response=>{
        console.log(response);
      setData({
        loading:false,
        error:response.error,
        message:response.message,
        results:response.data,
      });
      
      }).catch(e=>{
          setData({
          loading:false,
          error:true,
          message:e.message,
          results:[],
        });
      })
  }

  useEffect(()=>{
    getRatings();
  },[])
  return (
    <div className='body' >
      <Header/>
      <div className='addresses-wrapper'>
        <SmallScreenHeader title={"Ratings"}/>

        
        {
          data.error?
          <div>
            {data.message}
          </div>:
          data.loading?
          <Lottie 
          style={{width:50,height:50}} 
          loop 
          animationData={require("../../assets/lottie_animations/circle_progress.json")} />:
          data.results.length>0?
          <div
            style={{
              width:"100%",
              display:"flex",
              flexDirection:"column",
              gap:10
            }}
          >
            {
              data.results.map((item,index)=>{
              return <Rating key={index} item={item} />
            })
            }
          </div>:
          <div>
            No ratings found
          </div>
        }
      </div>
    </div>
  )
}

export default ProductRatings;