import React from 'react';
import "./index.css";
import { Slider } from '@mui/material';

const Filters = ({url}) => {
  return (
    <div className='filters'>
        <div className='filter' >
            <p className='heading'>Category</p>
            <div className='data'>
                <input type='checkbox'/>
                <label>Category 1</label>
                <br></br>
                <input type='checkbox'/>
                <label>Category 2</label>
            </div>
        </div>
        <div className='filter' >
            <p className='heading'>Price</p>
            <div className='data' style={{
                display:"flex",
                gap:15,
                alignItems:"center",
                justifyContent:"space-between"
            }}>
                <label>100</label>
                <Slider style={{color:"var(--app-color)"}} />
                <label>100000</label>
            </div>
        </div>
        <div className='filter' >
            <p className='heading'>Brands</p>
            <div className='data'>
                <input type='checkbox'/>
                <label>Brand 1</label>
                <br></br>
                <input type='checkbox'/>
                <label>Brand 2</label>
                <br></br>
            </div>
        </div>
    </div>
  )
}

export default Filters