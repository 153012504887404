import React from 'react'
import "./index.css";
const ProductLoading = () => {
  return (
    <div className='product-loading' >
        <div className='product-img' />
        <div className='desc' >
            <div className='desc-one' />
            <div className='desc-two'/>
            <div className='desc-three' />
        </div>
    </div>
  )
}

export default ProductLoading;