import React, { useEffect, useState } from 'react'
import { Advert, Banner, Header, HeaderHero, ProductsBanner, SmallScreenHeader } from '../../components'
import { useParams } from 'react-router-dom';
import { APPDATAURLS } from '../../utils/APPADATAURLS';

const ShopCategoryProductsPage = () => {
    const params = useParams();

    const [isSmallScreen,setIsSmallScreen] = useState(false);
    const [screenSize,sescreenSize] = useState(window.innerWidth);
    //load all adverts
    //load all categories

    useEffect(()=>{
        window.addEventListener("resize",(e)=>{
                if(e.target.innerWidth<800){
                    setIsSmallScreen(true);
                }else{
                    setIsSmallScreen(false)
                }
        });

        if(screenSize<800){
            setIsSmallScreen(true);
        }else{
            setIsSmallScreen(false)
        }

    },[screenSize]);
  return (
    <div
    className="body"
    >
        <Header/>
        <div
        className="body-wrapper"
        >
            <Advert/>
            <h4>{params.categoryName} products</h4>
            <SmallScreenHeader title={params.categoryName}/>
            <div style={{
                marginTop:50
            }} >
                <ProductsBanner url={`${APPDATAURLS.categoryProducts}${params.categoryID}&userID=`}/>
            </div>
        </div>
        
    </div>
  )
}

export default ShopCategoryProductsPage