import React, { useEffect, useState } from 'react'
import { Advert, Banner, BottomTabs, Category, Footer, Header, HeaderHero, ProductsBanner, SideBar, SmallScreenHeader } from '../../components'
import { FiChevronLeft, FiChevronsLeft } from 'react-icons/fi';
import { APPDATAURLS } from '../../utils/APPADATAURLS';
import "./shopPage.css";
import Lottie from 'lottie-react';
import { CategoriesLoading } from '../../components/Loading';

const ShopPage = () => {
  const [isSmallScreen,setIsSmallScreen] = useState(false);
    const [screenSize,sescreenSize] = useState(window.innerWidth);
    //load all adverts
    //load all categories
    const [isBigScreen,setIsBigScreen] = useState(true);

    useEffect(()=>{
        window.addEventListener("resize",(e)=>{
                if(e.target.innerWidth<800){
                    setIsSmallScreen(true);
                }else{
                    setIsSmallScreen(false)
                }
        })

        if(screenSize<800){
            setIsSmallScreen(true);
        }else{
            setIsSmallScreen(false)
        }

    },[screenSize]);

    const [categories,setCategories] = useState({
        data:[],
        loading:false,
        error:false,
        message:""
    });

    const fetchCategories = ()=>{
        setCategories({
        data:[],
        loading:true,
        error:false,
        message:""
        });
        fetch(APPDATAURLS.categories,{
        method:"get"
        }).then(req=>req.json()).then(response=>{
        setCategories({
        data:response.data,
        loading:false,
        error:response.error,
        message:response.message
        });
        }).catch(e=>{
        console.log(e);
        setCategories({
            data:[],
            loading:false,
            error:true,
            message:"An error occurred. Try again later"
        });
        })
    }
useEffect(()=>{
    window.scroll(0,0);
    fetchCategories();
    window.addEventListener('resize', ()=>{
            if(window.innerWidth < 800){
                return setIsBigScreen(false);
            }else{
                 return setIsBigScreen(true); 
            }
        });

        if(window.innerWidth < 800){
            return setIsBigScreen(false);
        }else{
           return setIsBigScreen(true); 
        }
    },[window.innerWidth])
  return (
    <div
    className="body"
    >
        <Header/>
        
        <div
        className="body-wrapper"
        >
            
            <Advert/>
            <Banner marginTop={isBigScreen?20:0} />

            <HeaderHero heading={"Categories"} toUrl={"#"} viewAll={false}/>
                {
                    categories.error?<div className='loading-container' ></div>:
                    categories.loading?
                    <div className='loading-container' >
                        {/*<Lottie style={{
                            width:50,
                            height:50
                        }} 
                        loop 
                        animationData={require("../../assets/lottie_animations/circle_progress.json")}/>*/}
                        <CategoriesLoading/>
                    </div>:
                    categories.data.length>0?
                    <div
                        className='categories'
                    >
                    {
                    categories.data.map((item,index)=>{
                        return <Category category={item} key={index} />
                    })
                    }
                    </div>:<div className='loading-container' >
                        <p>There is nothing here.</p>
                    </div>
                }
        
            
        </div>
        <BottomTabs/>
        <Footer/>
    </div>
  )
}

export default ShopPage