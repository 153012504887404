import React,{useState} from 'react'
import { Button, Card, Modal, Table } from 'react-bootstrap';
import {FaBackward,FaForward} from "react-icons/fa";

function Orders() {
    const [show,setShow] = useState(false);
    
  return (
    <div
    style={{
        marginTop:76
    }}
    >
        <div
    style={{
        marginTop:76,
        padding:10
    }}
    >

      <Modal
        show={show}
        onHide={()=>setShow(false)}
        animation={true}
        size='xl'
      >
        <Modal.Header closeButton >
          <Modal.Title>
            Order id: 000123
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Customer details</p>
          <p>Products</p>
          <p>Customer address</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
          onClick={()=>setShow(false)}
          >OK</Button>
        </Modal.Footer>
      </Modal>

      <Card>
        <Card.Header>
          <Card.Title>
            Orders
          </Card.Title>
        </Card.Header>
        <Card.Body>
            <Table responsive striped >
              <thead>
                <th>#</th>
                <th>orderID</th>
                <th>customer name</th>
                <th>product name</th>
                <th>product price</th>
                <th>quantity</th>
                <th>total</th>
                <th>date</th>
                <th>actions</th>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>000123</td>
                  <td>Customer 1</td>
                  <td>product 1</td>
                  <td>1000</td>
                  <td>2</td>
                  <td>2000</td>
                  <td>11/11/2023</td>
                  <td 
                  style={{
                    display:"flex",
                    gap:5
                  }}
                  >
                    <Button variant='primary' onClick={()=>setShow(true)} >View</Button>
                    <Button variant='secondary' >Mark as paid</Button>
                    <Button variant='danger' >Cancel</Button>
                  </td>
                </tr>
              </tbody>
            </Table>
        </Card.Body>
        <Card.Footer>
            <Button
          variant='success'
          onClick={()=>setShow(false)}
          style={{
            marginRight:5,
            gap:5
          }}
          >
            <FaBackward/>
          </Button>
        <Button
          onClick={()=>setShow(false)}
          style={{
            marginRight:5,
            gap:5
          }}
          >
            <FaForward/>
            </Button>
        </Card.Footer>
      </Card>

      
    </div>
    </div>
  )
}

export default Orders;