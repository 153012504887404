import React from 'react';
import "./index.css";
import { Link } from 'react-router-dom';
import { APIURL, BASEURL } from '../../utils/APPADATAURLS';
import CustomButton from '../CustomButton';
import { Modal, Toast } from 'react-bootstrap';
import { useState } from 'react';
import RateProduct from '../Rating/RateProduct';
import { FaBookmark, FaChevronDown, FaDiceOne, FaMarkdown } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';

const Order = ({order,refresh,setRefresh}) => {
  const [modalShown,setModalShown] = useState(false);
  const [productIndex,setProductIndex] = useState(0);
  const [imageLoaded,setImageLoaded] = useState(false);

  const [showDetails,setShowDetails] = useState({
    payment:false,
    items:false
  });

  const [received,setReceived] = useState({
            loading:false,
            error:false,
            message:"",
            results:null
        });
  const markAsReceived = ()=>{
       setReceived({
            loading:true,
            error:false,
            message:"",
            results:null
        });

    let formData = new FormData();
    formData.append("mark-as-received","mark");
    formData.append("orderID",order.id);

    fetch(APIURL,{
        method:"POST",
        body:formData
    })
    .then(req=>req.json())
    .then(response=>{
        setReceived({
            loading:false,
            error:response.error,
            message:response.message,
            results:response.data});
            setRefresh(!refresh);
    }).catch(err=>{
        console.log(err);
        setReceived({
            loading:false,
            error:true,
            message:"An error occurred! Try again later",
            results:null});
    });
}



  return (
    <div className='order' 
    style={{
      position:"relative",
      transition:"height .5 ease-in-out",
      height:"auto"
    }} >
      <Toast show={received.error} onClose={()=>{
            setReceived({...received,error:false,message:""});
        }}
        
        style={{
            position:"fixed",
            top:0,
            right:0,
            zIndex:300,
            backgroundColor:"red"
        }}
        
        > 
        <Toast.Header closeButton />
            <Toast.Body>
                {
                    received.message
                }
            </Toast.Body>
      </Toast>
      
      <div
        style={{
            position:"absolute",
            right:5,
            top:5,
            display:'flex',
            flexDirection:"row",
            alignItems:"center",
            gap:10,
            color:order.isDelivered?"green":"gray",
            fontSize:14,
            fontWeight:700,
        }}
        >
            {order.isDelivered?"Delivered":"Pending"}
            {order.status=="Canceled"?<FiX/>:order.status=="Pending"?<FaBookmark/>:<FaDiceOne/>}
        </div>
      <Modal
      show={modalShown}
      centered
      onHide={()=>setModalShown(false)}
      onBackdropClick={()=>setModalShown(false)}
      >
          <Modal.Header closeButton >
            Rate {order.data[productIndex].productName}
          </Modal.Header>
          <Modal.Body>
            <RateProduct product={order.data[productIndex]} setModalShown={setModalShown}/>
          </Modal.Body>
      </Modal>

        <label className='title' >Order : {order.orderNumber}</label>
        <label>Status: {order.status}</label>
        
        <label>No. of items: {order.data.length} items</label>
        <label>Payment : {order.isPaid?"Paid":"Not paid"}</label>

        <label 
        className='title'
        style={{display:showDetails.payment?"none":"flex"}} >
          Payment Details <AiOutlineDown onClick={()=>setShowDetails({...showDetails,payment:true})}  /> </label>
        {
          order.isCanceled==1?
          <></>:
          <>
            <div className='payment' style={{display:showDetails.payment?"flex":"none"}} >
              <label className='title' >Payment Details 
                <AiOutlineUp onClick={()=>setShowDetails({...showDetails,payment:false})}/> 
              </label>
              <label>REF : {order.transaction.paymentMPesaID}</label>
              <label>Amount : Ksh.{order.transaction.amount - order.delivery_cost}</label>
              <label>shipment fee : Ksh.{order.delivery_cost}</label>
              <label>Total : Ksh.{order.transaction.amount}</label>
              <label>Date : {order.transaction.date}</label>
            </div>
          </>
        }
        <label className='title' style={{display:showDetails.items?"none":"flex"}} >Items 
            <AiOutlineDown onClick={()=>setShowDetails({...showDetails,items:true})}/> 
        </label>
        <div className='payment'style={{display:showDetails.items?"flex":"none"}} >
          <label className='title' >Items 
            <AiOutlineUp onClick={()=>setShowDetails({...showDetails,items:false})}/> 
          </label>
          {
          order.data.map((item,index)=>{
            return <Link
                  to={`/products/${item.productName}/${item.productID}`}
                  className='item'
                >
              <img  style={{
                display:imageLoaded?"flex":"none",
              }}
                src={`${BASEURL}/${item.productImage}`}
               
                onLoad={(e)=>{
                  setImageLoaded(true)}}
                onError={(e)=>{
                  setImageLoaded(false)}}
                onLoadStart={(e)=>{
                }}
                onLoadedData={(e)=>{
                }}
            />

            <img
              style={{
                display:imageLoaded?"none":"flex",
              }}
            className='default'
            src='/assets/images/savo_logo.jpg'
            />
                  <div>
                    <label>{item.productName.length>40?`${item.productName.substring(0,30)}...`:item.productName} x {item.saleOrderQuantity}</label>
                    <label>Price : Ksh.{item.productPrice}</label>
                    <label>Total : {item.saleOrderTotal}</label>
                    <div
                    style={{
                      position:"absolute",
                      width:"50%",
                      bottom:0,
                      right:0,
                      display:order.isDelivered?"flex":"none"
                    }}
                    onClick={(e)=>{
                      e.stopPropagation();
                      e.preventDefault();
                      setProductIndex(index);
                      setModalShown(true);
                    }}
                  >
                    <CustomButton 
                    title={"Rate"}
                    backgroundColor={"var(--app-color)"}
                    />
                  </div>
                  </div>
                  
                  
                </Link>
          })
        }
        </div>

        {
          !order.isDelivered && order.deliveryMade?
          <CustomButton
          title={received.loading?"Loading...":"Mark as received"}
          backgroundColor={"var(--app-color)"}
          pressAction={()=>markAsReceived()}
          disabled={received.loading?true:false}
          />:<></>
        }
        
    </div>
  )
}

export default Order;