import React,{useContext, useEffect, useRef,useState} from 'react'
import { Fragment } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import "./index.css";

import { APPDATAURLS, BASEURL } from '../../utils/APPADATAURLS';
import { FiLoader } from 'react-icons/fi';
import Lottie from 'lottie-react';
import Product from '../Product';
import { ProductsLoading } from '../Loading';
import { Button } from 'react-bootstrap';


const ProductsBanner = ({url=`${APPDATAURLS.products}&userID=`,marginTop=0,data,hasCategories=false}) => {
    const banner_holder_ref = useRef(0);
    const [isHidden,setIsHidden] = useState(true);
    const [retry,setRetry] = useState(false);
    const [visibleArrow,setVisibleArrow] = useState({
        right:false,
        left:true,
        scrolledWidth:0
    });


    const [products,setProducts] = useState({
        data:[],
        loading:false,
        error:false,
        message:""
    });

    const fetchProducts = ()=>{
        setProducts({
        data:[],
        loading:true,
        error:false,
        message:""
        });
        fetch(url,{
        method:"get"
        }).then(req=>req.json()).then(response=>{
        setProducts({
        data:response.data,
        loading:false,
        error:response.error,
        message:response.message
        });
        }).catch(e=>{
        console.log(e);
        setProducts({
            data:[],
            loading:false,
            error:true,
            message:"An error occurred. Try again later"
        });
        })
    }

    useEffect(()=>{
        if(data){
            setProducts({
            data:data.data,
            loading:false,
            error:data.error,
            message:data.message
            });
        }else{
            fetchProducts();
        }
        
    },[retry]);

  return (
    <div
        
        className='banner-holder'
        style={{
            marginTop
        }}
        onMouseEnter={()=>setIsHidden(false)}
        onMouseLeave={()=>setIsHidden(true)}
    >
        <div
            className={`move_button prev ${!isHidden && (visibleArrow.scrolledWidth<(260*products.length))?"move_button_visible":"move_button_hidden"}`}
        >
            <div className='icon' >
                <FaChevronLeft  
                    size={30} 
                    fill={"#ffffff"} 
                    onClick={()=>{
                        banner_holder_ref.current.scrollLeft += 270;
                        setVisibleArrow({...visibleArrow,scrolledWidth:visibleArrow.scrolledWidth+270});
                    }}
                />
            </div>
            
        </div>

        
            
            {
                products.error?<div
                className='data-holder loading-container'
                style={{
                    flexDirection:"column",
                    gap:10
                }}
                >
                    Ooops! An error occurred. Try again later.
                    <Button
                    onClick={()=>setRetry(!retry)}
                    style={{
                        backgroundColor:"var(--app-color)",
                        color:"#ffffff",
                        border:"none"
                    }} >Retry</Button>
                </div>:
                products.loading?<div
                className='data-holder loading-container'
                >{/*<Lottie style={{
                    width:50,
                    height:50
                }} loop animationData={require("../../assets/lottie_animations/circle_progress.json")}/>*/}
                <ProductsLoading/>
                
                </div>:
                products.data.length>0?
                <div 
                ref={banner_holder_ref} 
                className={hasCategories?"banner-container":"no-categories-banner-container"}
                >{
                products.data.map((item,index)=>{
                    return<Product
                        key={index}
                        product={item}
                    />
                })}</div>:
                data?
                <></>:
                <div
                className='data-holder'
                >
                    Their is nothing here!
                </div>
            }
        
        <div className={`move_button next ${!isHidden && visibleArrow.scrolledWidth>=270?"move_button_visible":"move_button_hidden"}`} >
            <div className='icon' >
                <FaChevronRight 
                    size={30} 
                    fill={"#ffffff"}
                    onClick={()=>{
                        banner_holder_ref.current.scrollLeft -= 270;
                        setVisibleArrow({...visibleArrow,scrolledWidth:visibleArrow.scrolledWidth-270});
                    }}
                    />
                </div>
        </div>
    </div>
  )
}



export default ProductsBanner