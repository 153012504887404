import React, { useEffect, useRef, useState } from 'react'
import { useFetchData } from '../../hooks'
import { APPDATAURLS, BASEURL } from '../../utils/APPADATAURLS';
import "./index.css";

export const TrialPage = () => {
    const [activeIndex,setActiveIndex] = useState(0);
    const bodyRef = useRef();
    const h1Ref = useRef();
    const animateText = ()=>{
        //const text = h1Ref.current.textContent;
        if(results.length>0)
            {
                let i = 0;
                let id = setInterval(()=>{
                    h1Ref.current.innerHTML += `<span class='span' >${results[activeIndex].productName[i]}</span>`
                    i++;
                    if(i >= results[activeIndex].productName.length ){
                        clearInterval(id);
                    }
                },300);
            }
    }
    const {loading,results,error,message} = useFetchData(APPDATAURLS.adverts,{method:"GET"});
    useEffect(()=>{
        animateText();
    },[activeIndex]);
  return (
    <div
    className='page'
    style={{
        backgroundImage:`url(${results.length>0?BASEURL+"/"+results[activeIndex].productImage:null})`
    }}
    >
        <div className='container'>
            <div className='one'>
                <h1 ref={h1Ref} >
                {/* {results.length>0?results[activeIndex].productName:"Hello there"} */}
                </h1>
            </div>

            <div className='two' ref={bodyRef}  >
                <button
                onClick={()=>{
                    setActiveIndex(activeIndex>0?activeIndex-1:0)
                }}
                className='previous' >Previous</button>
                <button
                onClick={()=>{
                    setActiveIndex(activeIndex<results.length?activeIndex+1:results.length-1);
                    console.log("");
                }}
                className='next' >Next</button>

                {
                    error?
                    <label>{message}</label>:
                    loading?
                    <label>...</label>:
                    results.map((item,index)=>{
                        return(
                            <img
                            style={{
                                transform:`translate3d(100% 100% 0%)`
                            }}
                            
                            onClick={()=>setActiveIndex(index)} src={`${BASEURL}/${item.productImage}`} />
                        )
                    })
                }
            </div>
        </div>
        
    </div>
  )
}
